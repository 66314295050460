import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import PostCard from "../components/components/common/PostCard";
import Pagination from "../components/components/common/Pagination";
import { Grid } from "../elements/Grid";
import { Flex } from "../elements/Flex";
import { Heading } from "../elements/Heading";

const Index = ({ data, pageContext }) => {
  const posts = data.allGhostPost.edges;
  const { pageTitle } = pageContext;

  return (
    <>
      <Flex bg="pink" p={5} justifyContent="center">
        <Heading>{pageTitle}</Heading>
      </Flex>

      <Grid
        p={3}
        gridTemplateColumns="repeat(3, 1fr)"
        gridGap={3}
        mx="auto"
        display={["block", "block", "grid"]}
        maxWidth="1050px"
      >
        {posts.map(({ node }) => (
          <PostCard key={node.id} post={node} />
        ))}
      </Grid>
      <Pagination pageContext={pageContext} />
    </>
  );
};

Index.propTypes = {
  data: PropTypes.shape({
    allGhostPost: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  pageContext: PropTypes.object,
};

export default Index;

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
  query GhostPostQuery($limit: Int!, $skip: Int!, $type: String) {
    allGhostPost(
      sort: { order: DESC, fields: [published_at] }
      limit: $limit
      skip: $skip
      filter: { tags: { elemMatch: { name: { eq: $type } } } }
    ) {
      edges {
        node {
          # Main fields
          id
          title
          slug
          featured
          feature_image
          excerpt
          custom_excerpt
          visibility

          # Dates formatted
          created_at_pretty: created_at(formatString: "DD MMMM, YYYY")
          published_at_pretty: published_at(formatString: "DD MMMM, YYYY")
          updated_at_pretty: updated_at(formatString: "DD MMMM, YYYY")

          # Dates unformatted
          created_at
          published_at
          updated_at

          # SEO
          meta_title
          meta_description
          og_description
          og_image
          og_title
          twitter_description
          twitter_image
          twitter_title

          # Authors
          authors {
            name
            slug
            bio
            # email
            profile_image
            twitter
            facebook
            website
          }
          primary_author {
            name
            slug
            bio
            # email
            profile_image
            twitter
            facebook
            website
          }

          # Tags
          primary_tag {
            name
            slug
            description
            feature_image
            meta_description
            meta_title
            visibility
          }
          tags {
            name
            slug
            description
            feature_image
            meta_description
            meta_title
            visibility
          }

          # Content
          plaintext
          html

          # Additional fields
          url
          canonical_url
          uuid
          page
          codeinjection_foot
          codeinjection_head
          codeinjection_styles
          comment_id
          reading_time
        }
      }
    }
  }
`;
