import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { readingTime as readingTimeHelper } from "@tryghost/helpers";
import { Flex } from "../../../elements/Flex";
import { Image } from "../../../elements/Image";
import { Heading } from "../../../elements/Heading";
import { Text } from "../../../elements/Text";
import { Button } from "../../../elements/Button";

const PostCard = ({ post }) => {
  const { title, created_at_pretty, excerpt, slug, feature_image, tags } = post;

  const type = tags.find((item) => item.name === "Blog") ? "blog" : "all-recipies";

  const url = `/blogs/${type}/${slug}/`;
  const readingTime = readingTimeHelper(post);

  return (
    <Flex flexDirection="column" textAlign="left" mb={3} maxWidth="350px" mx="auto">
      <Link to={url} className="post-card">
        <Image src={feature_image} objectFit="cover" width="100%" height="300px" />{" "}
      </Link>
      <Flex flexDirection="column">
        <Link to={url}>
          <Heading my={1} fontSize={17} color="grey">
            {title}
          </Heading>
        </Link>

        <Text my={1} fontSize={14} fontWe>
          {created_at_pretty} - {readingTime}
        </Text>
        <Text color="grey" fontSize={14} truncate>
          {excerpt}
        </Text>

        <Link to={url}>
          {" "}
          <Button mt={3} variant="secondary">
            READ MORE
          </Button>
        </Link>
      </Flex>
    </Flex>
  );
};

PostCard.propTypes = {
  post: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    feature_image: PropTypes.string,
    featured: PropTypes.bool,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      })
    ),
    excerpt: PropTypes.string.isRequired,
    primary_author: PropTypes.shape({
      name: PropTypes.string.isRequired,
      profile_image: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default PostCard;
