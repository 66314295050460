import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { Flex } from "../../../elements/Flex";
import { Text } from "../../../elements/Text";
import { BsArrowRight } from "react-icons/bs";
import { BsArrowLeft } from "react-icons/bs";

const Pagination = ({ pageContext }) => {
  const { previousPagePath, nextPagePath, humanPageNumber, numberOfPages } = pageContext;

  return (
    <Flex p={3} width="100%" justifyContent="center">
      <Flex justifyContent="center" alignItems="center">
        {previousPagePath && (
          <Link to={previousPagePath} rel="prev">
            <BsArrowLeft size={20} />
          </Link>
        )}
      </Flex>
      {numberOfPages > 1 && (
        <Text className="pagination-location" mx={2}>
          Page {humanPageNumber} of {numberOfPages}
        </Text>
      )}
      <Flex justifyContent="center" alignItems="center">
        {nextPagePath && (
          <Link to={nextPagePath} rel="next">
            <BsArrowRight size={20} />
          </Link>
        )}
      </Flex>
    </Flex>
  );
};

Pagination.propTypes = {
  pageContext: PropTypes.object.isRequired,
};

export default Pagination;
